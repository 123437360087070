<template>
  <div class="font-roboto text-border-dark">
    <div
      :class="[
        'flex flex-row',
        {
          'justify-end': position === 'end',
          'justify-start': position === 'start',
        },
      ]"
    >
      <div class="dot flexbox-item" :class="dotColorClass"></div>
      <div class="flexbox-item leading-sm pl-xs">
        {{ t(getAvailabilityTransKey()) }}
      </div>
      <div v-if="showHint" class="self-center pl-xs">
        <DynamicPopup>
          <template #trigger>
            <Icon
              icon-class="fad fa-circle-info"
              style="
                --fa-secondary-color: var(--thm-status-warn-base);
                --fa-secondary-opacity: 1;
              "
            />
          </template>
          <template #content>
            <InfoFrame>
              <div class="max-w-[450px]">
                {{ t('shop.product.availabilityState.discontinuedItem.info') }}
              </div>
            </InfoFrame>
          </template>
        </DynamicPopup>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { DynamicPopup, Icon } from '~/complib';
import InfoFrame from '~/components/formFields/components/infoFrame.vue';
import {
  ProductAvailability,
  type ProductAvailabilityState,
} from '~/@types/product-availibility';

const { t } = useTrans();

const props = defineProps({
  stock: {
    type: Number,
    required: true,
  },
  availabilityState: {
    type: String as PropType<ProductAvailabilityState>,
    required: true,
    default: ProductAvailability.AVAILABLE,
  },
  position: {
    type: String as PropType<'start' | 'end'>,
    required: false,
    default: 'start',
  },
});

const dotColorClass = ref('');

const getAvailabilityTransKey = () => {
  switch (props.availabilityState) {
    case ProductAvailability.PERMANENTLY_AVAILABLE:
      dotColorClass.value = 'bg-status-success-base';
      return 'shop.product.availabilityState.available';
    case ProductAvailability.NOT_BUYABLE:
      dotColorClass.value = 'bg-status-danger-base';
      return 'shop.product.availabilityState.notAvailable';
    case ProductAvailability.TMP_NOT_BUYABLE:
      dotColorClass.value = 'bg-status-danger-base';
      return 'shop.product.availabilityState.tmpNotAvailable';
    case ProductAvailability.STOCK_DEPENDENT:
      if (isStockSufficient()) {
        dotColorClass.value = 'bg-status-success-base';
        return 'shop.product.availabilityState.available';
      }

      dotColorClass.value = 'bg-status-danger-base';
      return 'shop.product.availabilityState.notAvailable';
    default:
      if (isStockSufficient()) {
        dotColorClass.value = 'bg-status-success-base';
        return 'shop.product.availabilityState.available';
      }

      dotColorClass.value = 'bg-status-warn-base';
      return 'shop.product.availabilityState.availableSoon';
  }
};

const showHint = computed(() => {
  return props.availabilityState === ProductAvailability.DISCONTINUED_ITEM;
});

const isStockSufficient = () => {
  return props.stock > 0;
};
</script>

<style scoped lang="postcss">
.dot {
  @apply w-[8px] md:w-xs h-[8px] md:h-xs mr-[5px] p-1 rounded-lg inline-block self-center;
}
</style>
